import React from 'react';

function Home() {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2>Welcome to HamHAW</h2>
      <p style={{ lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
        The HamHAW Amateur Radio Health and Welfare System is a brand new organization dedicated to supporting emergency health and welfare.<br /><br />
        We are letting the government and other official agencies handle search and rescue emergency response while we focus on helping find people.<br /><br />
        Whenever someone asks "Where is grandma?", we want to lead the effort to find her.<br /><br />
        That simple. No red tape. All ham home-grown right here.
      </p>
      <p>
      <a href="https://tally.so/r/wk75v6" target="_blank" rel="noopener noreferrer">
        <button style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
          Join Our Mailing List
        </button>
      </a>
      </p>
    </div>
  );
}

export default Home;
