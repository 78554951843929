import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';  // Import the Layout component
import Home from './components/Home';
import HamAccess from './components/HamAccess';
import QuickCheck from './components/QuickCheck';
import FindPerson from './components/FindPerson';
import FullList from './components/FullList';
import FindDetails from './components/FindDetails';

function App() {
  return (
    <Router>
      <Layout>  {/* Wrap all routes inside Layout */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ham-access" element={<HamAccess />} />
          <Route path="/quick-check" element={<QuickCheck />} />
          <Route path="/find-person" element={<FindPerson />} />
          <Route path="/full-list" element={<FullList />} />
          <Route path="/find-details/:id" element={<FindDetails />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;