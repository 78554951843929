import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

const FindPerson = () => {
  const [formData, setFormData] = useState({
    firstName: '', lastName: '', city: '', state: '', age: '', race: '', gender: '', lastSeen: '',
    reporterFirstName: '', reporterLastName: '', relationship: '', email: '', phone: '', reporterCity: '', reporterState: ''
  });
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase
      .from('missing_persons')
      .select('*')
      .match({ firstName: formData.firstName, lastName: formData.lastName });

    if (error) {
      console.error('Error finding persons', error);
    } else if (data.length > 0) {
      setSearchResults(data);
    } else {
      const { error: insertError } = await supabase
        .from('missing_persons')
        .insert([{
          firstName: formData.firstName,
          lastName: formData.lastName,
          city: formData.city,
          state: formData.state,
          age: formData.age,
          race: formData.race,
          gender: formData.gender,
          lastSeen: formData.lastSeen
        }]);

      if (insertError) {
        console.error('Error inserting new person', insertError);
      } else {
        console.log('Person added to the database.');
      }
    }
  };

  return (
    <div>
      <h2>Find a Person</h2>
      <form onSubmit={handleSubmit}>
        {/* Form fields for search criteria and reporter details */}
        <button type="submit">Submit</button>
      </form>
      {/* Display search results */}
    </div>
  );
};

export default FindPerson;