import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

const QuickCheck = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '', city: '', state: '' });
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase
      .from('missing_persons')
      .select('*')
      .ilike('firstName', `%${formData.firstName}%`)
      .ilike('lastName', `%${formData.lastName}%`)
      .ilike('city', `%${formData.city}%`)
      .ilike('state', `%${formData.state}%`);

    if (error) {
      console.error('Error fetching data', error);
    } else {
      setSearchResults(data);
    }
  };

  return (
    <div>
      <h2>Quick Check</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} />
        <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
        <input type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} />
        <input type="text" name="state" placeholder="State" value={formData.state} onChange={handleChange} />
        <button type="submit">Search</button>
      </form>
      <ul>
        {searchResults.map((person) => (
          <li key={person.id}>
            {person.firstName} {person.lastName} - {person.city}, {person.state}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickCheck;