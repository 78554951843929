import React from 'react';
import banner from '../assets/HamHAWWebBanner.png';  // Ensure the path to your banner is correct

function Layout({ children }) {
  return (
    <div>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <img src={banner} alt="HamHAW Banner" className="hamhaw-banner" />
      </div>
      <div>
        {children}  {/* This renders the content of the current page */}
      </div>
    </div>
  );
}

export default Layout;
