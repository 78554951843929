import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // New method
import { supabase } from '../supabaseClient';

const HamAccess = ({ setLoggedInUser }) => {
  const [formData, setFormData] = useState({ callSign: '', password: '' });
  const navigate = useNavigate();  // New hook

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error, user } = await supabase.auth.signIn({
      email: formData.email,
      password: formData.password,
    });

    if (error) {
      console.error('Login failed', error);
    } else {
      setLoggedInUser(user.user_metadata.callSign);
      navigate('/quick-check');  // Updated navigation
    }
  };

  return (
    <div>
      <h2>Ham Access</h2>
      {/* Form and other components */}
    </div>
  );
};

export default HamAccess;
