import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

const FindDetails = ({ match }) => {
  const [details, setDetails] = useState({});
  const [foundDetails, setFoundDetails] = useState({ status: 'found', notes: '' });

  const handleUpdate = async () => {
    const { error } = await supabase
      .from('missing_persons')
      .update({ status: foundDetails.status, notes: foundDetails.notes })
      .eq('id', match.params.id);

    if (error) console.error('Error updating person status', error);
    else console.log('Person status updated successfully');
  };

  return (
    <div>
      <h2>Person Details</h2>
      {/* Details form and update functionality */}
    </div>
  );
};

export default FindDetails;